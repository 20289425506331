/**
 * Font family
 */
@mixin font($value: null) {
  @if ($value) {
    font-family: $value, Arial, sans-serif;
  } @else {
    font-family: Arial, Helvetica, sans-serif;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Border radius
 */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

/**
 * Scale
 */
@mixin scale($scale) {
  -webkit-transform: scale($scale);
  -moz-transform: scale($scale);
  -ms-transform: scale($scale);
  -o-transform: scale($scale);
  transform: scale($scale);
}

/**
 * Translate
 */
@mixin translate($translateX,$translateY) {
  -webkit-transform: translate($translateX,$translateY);
  -moz-transform: translate($translateX,$translateY);
  -ms-transform: translate($translateX,$translateY);
  -o-transform: translate($translateX,$translateY);
  transform: translate($translateX,$translateY);
}

/**
 * Translate
 */
@mixin translateScale($translateX,$translateY,$scale) {
  -webkit-transform: translate($translateX,$translateY) scale($scale);
  -moz-transform: translate($translateX,$translateY) scale($scale);
  -ms-transform: translate($translateX,$translateY) scale($scale);
  -o-transform: translate($translateX,$translateY) scale($scale);
  transform: translate($translateX,$translateY) scale($scale);
}

/**
 * Skew
 */
@mixin skewX($skewX) {
  -webkit-transform: skewX($skewX);
  -moz-transform: skewX($skewX);
  -ms-transform: skewX($skewX);
  -o-transform: skewX($skewX);
  transform: skewX($skewX);
}

/**
 * Rotate
 */
@mixin rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  transform: rotate($rotate);
}

/**
 * Transition
 */
@mixin transition($prop: all, $number: .2, $delay: null) {
  transition: #{$number}s $prop $delay;
}

/**
 * REM units
 */
$baseFontSize: 16;

@function parse-int($n) {
  @return $n / ($n * 0 + 1);
}

@mixin rem($type, $values) {
  $px: ();
  $rem: ();
  $root: $baseFontSize;

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $rem: append($rem, $value);
    }

    @else if type-of($value) == number {
      $unit: unit($value);
      $val: parse-int($value);

      @if $unit == 'px' {
        $rem: append($rem, ($val / $root + rem));
      }

      @if $unit == 'rem' {
        $rem: append($rem, $value);
      }
    }

    @else {
      $rem: append($rem, $value);
    }
  }

  @if $px == $rem {
    #{$type}: $px;
  } @else {
    #{$type}: $px;
    #{$type}: $rem;
  }
}

@function rem($value) {
  $root: $baseFontSize;
  $val: parse-int($value);
  $return: ();

  @if unit($value) == 'px' {
    $return: append($return, ($val / $root + rem));
  } @else {
    $return: append($return, ($val * $root + px));
  }

  @return $return;
}