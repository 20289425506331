/************************************************************************************
HEADER
*************************************************************************************/

.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 300;
  background: #fff;

  &.animated {
    .type {
      opacity: 1;
    }
  }

  .inner {
    @include rem(height, 64px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //@include transition(all, 2);
  }

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .type {
    @include rem(max-width, 21px);
    display: flex;
    align-items: center;
    @include transition(all, 2);
    //@include scale(0);
    opacity: 0;

    &.alt {
      @include rem(max-width, 24px);
    }
  }
}

.teaser {
  overflow: hidden;
  position: relative;
  background: #000;

  img {
    display: block;
    margin: 0 auto;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.darken {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.7);
    }
  }

  &.alt {
    min-height: 0;
    color: #fff;

    .note {
      @include rem(font-size, 13px);
      margin: 0;
    }

    .teaser-content {
      height: auto;
      min-height: 0;
      @include rem(padding, 100px 0 80px 0);
      @include rem(margin-top, 64px);
      overflow: hidden;
    }



    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .nav-inactive {
      @include rem(font-size, 16px);
      line-height: 1.5em;

      ul {
        li {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  &.empty {
    @include rem(min-height, 120px);
    background: none;
    position: static;

    .teaser-content {
      display: block;
      height: auto;
      @include rem(min-height, 56px);
      position: static;
      @include rem(padding, 30px 0 0 0);
      @include rem(margin-top, 64px);
    }
  }

  &.disabled {
    .teaser-content {
      opacity: 0;
    }
  }

  &.subpage-navigation {
    .secondary-nav {
      @include translate(0,0);
      position: relative;
      left: 0;
      top: 0;
    }

    .nav-active {
      display: none;
    }

    .nav-inactive {
      display: block;
      //position: relative;
    }

    &.active {
      background: #000;

      .teaser-content {
        height: auto;
        min-height: 0;
        @include rem(padding, 104px 0 40px 0);
        margin-top: 0;
        //@include rem(margin-top, 64px);
        overflow: hidden;
      }

      .nav-active {
        display: block;
      }

      .nav-inactive {
        display: none;
      }
    }
  }

  .scroll-down {
    @include rem(font-size, 14px);
    line-height: 1.5em;
    position: absolute;
    left: 50%;
    @include rem(bottom, 20px);
    z-index: 110;
    @include translate(-50%,0);
    color: #fff;
    text-decoration: none;
    letter-spacing: .1em;
    text-align: center;
    @include transition(all, .3);

    .icon {
      display: block;
      @include rem(margin, 0 auto 20px auto);
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }
  }

  .head {
    align-self: flex-end;
    align-items: center;
    position: relative;
    //min-height: 174px;

    .h2, h2 {
      color: #526931;

      &.medium {
        font-weight: 100;
      }
    }

    &.active {
      .inner {
        visibility: hidden;
      }
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.teaser-hp {
  .mobile-nav {
    display: none;
  }
}

.teaser-project {
  min-height: 400px;
  //@include rem(padding-top, 64px);
  max-height: 100vh;

  .teaser-text {
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include rem(padding-top, 64px);
  }

  .teaser-content {
    min-height: 400px;
    max-height: 100vh;
  }

  .teaser-img {
    position: relative;
  }

  .nav-inactive {
    .head {
      align-self: flex-end;
      color: #fff;

      h1 {
        color: #fff;
        margin-bottom: 0;
      }

      .subhead {
        @include rem(font-size, 20px);

		a {
			color: #fff;
			text-decoration: none;
		}
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 10;
  }
}

.teaser-news-detail {
  .teaser-content {
    @include rem(min-height, 600px);
  }
}

.teaser-content {
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100vh;
  //min-height: 730px;
  min-height: 100vh;
  position: relative;
  z-index: 100;
  //padding: 0 15px;
  @include rem(padding-top, 104px);
  @include rem(padding-bottom, 40px);

  h1 {
    text-transform: uppercase;
    font-weight: 400;
    @include rem(margin-right, 40px);
    letter-spacing: .1em;
    color: #fff;
  }
}

.teaser-content-inner {
  width: 100%;
}

.teaser-img {
  opacity: 1;
  @include transition(all, 1);

  &.disabled {
    opacity: 0;
  }
}

.primary-nav {
	//max-width: 1110px;
  padding: 40px 15px;
  @include rem(padding-top, 40px);
  @include rem(padding-bottom, 40px);
  position: relative;
  align-items: center;
  @include transition(all, .5);

  h1 {
    margin: 0;
    position: absolute;
    left: 0;
    top: 50%;
    @include translate(0,-50%);
    font-weight: 300;
    //max-width: 200px;
    opacity: 1;
    @include transition(all, .5);

    &.disabled {
      left: 30px;
      opacity: 0;
    }
  }

  &.titled {
    //@include rem(padding-left, 150px);
    //@include rem(margin-right, -150px);
  }
}

.secondary-nav {
  color: #fff;
  overflow: hidden;
  @include transition(all, .3);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  @include translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include rem(margin, 0 -20px);

    li {
      @include rem(font-size, 23px);
      padding: 0;
      @include transition(all, .3);
      text-transform: uppercase;

      a {
        letter-spacing: .3em;
        color: #fff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &.lng {
        flex: 0 0 100%;
        text-align: center;
        //@include rem(padding, 20px 10px);
        padding: 10px 10px;

        a {
          @include rem(font-size, 15px);
          @include rem(padding, 0 20px);
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    max-height: 700px;

    ul {
      width: auto;
      @include rem(margin, 0 -20px);

      li {
        @include rem(padding, 0 20px);

        &.lng {
          @include rem(padding, 10px 30px);
        }
      }
    }
  }
}

.teaser-nav {
  @include rem(font-size, 16px);
  letter-spacing: .2em;
  position: relative;
  color: #fff;
  @include transition(all, .3);
  left: 0;
  top: 0;
  opacity: 1;
  //flex-shrink: 0;

  &.hp-nav {
    max-width: 1110px;
    color: #6FA02B;
    display: block;
    margin: 0 auto;

    a:not(.x-btn) {
      color: #6FA02B;
      opacity: 0;
      pointer-events: none;
      @include transition(all,1);

      &.animated {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .forest {
      @include transition(all, 1);
    }

    &.active {
      .forest {
        color: #3C4D24;
      }

      circle {
        &.animated {
          opacity: 1;
          pointer-events: auto;
        }
      }

      line {
        &.animated {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  svg {
    fill: currentColor;
    max-width: 100%;
    height: auto;
    @include transition(all, .3);
    overflow: visible;
  }

  a {
    display: block;
    text-decoration: none;
    fill: currentColor;
    //color: #fff !important;
    color: #fff;
    position: relative;
    z-index: 10;
    @include transition(all, .3);
  }

  .link {
    letter-spacing: .2em;
    line-height: 1.4em;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    z-index: 100;
    @include translate(-50%,-50%);
    white-space: nowrap;
    opacity: 0;
    color: #fff;
    @include transition(all,1);

    &.animated {
      opacity: 1;
    }

    &.big {
      @include rem(font-size, 25px);
      color: #fff !important;
    }

    &.small {
      color: #fff !important;

      &.disabled {
        display: none;
      }
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  circle {
    fill: transparent;
    stroke: #fff;
    opacity: 0;
    pointer-events: none;
    @include transition(all,1);
    cursor: pointer;

    &.active {
      fill: #fff;
    }

    &.disabled {
      display: none;
    }
  }

  line {
    stroke: #fff;
    opacity: 0;
    pointer-events: none;
    @include transition(all,1);

    &.disabled {
      display: none;
    }
  }
}

.nav-link,
.forest,
.teaser-nav .link {
  &.disabled {
    opacity: 0 !important;
    pointer-events: none !important;
  }
}

.nav-btn-wrap {
  position: absolute;
  width: 100%;
  @include rem(top, 94px);
  z-index: 200;
  height: 0;
  //z-index: 2001;
  //pointer-events: none;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;


  .nav-language-menu {
	ul {
	  display: flex;
	  justify-content: center;
	  flex-direction: row;
	  //flex-wrap: wrap;
	  @include rem(font-size, 15px);
	  padding: 0;
	  @include transition(all, .3);
	  text-transform: uppercase;
	  color: white;
	  margin-bottom: 0;

	  li {
		padding: 0 10px;

		a {
		  letter-spacing: .3em;
		  color: #fff;
		  text-decoration: none;

		  &:hover {
			text-decoration: underline;
		  }
		}

		&:last-of-type {
		  padding-right: 0;
		}
	  }

	  &.lng {
		display: flex;
		position: absolute;
		right: 0;
		top: 0;

		a {
		  @include rem(font-size, 15px);
		  @include rem(padding, 0 10px);
		}
	  }
	}
  }

  &-hidden {
    display: none;
  }
}
.black-nav {
  .nav-language-menu {
	//color:black;
	ul{
	  color: black;

	  li {
		a {
		  color: black;
		}
	  }
	}
  }

  .nav-btn {
	.lines {
	  .line-1 {
		background: black;
	  }
	  .line-2 {
		background: black;
	  }
	  .line-3 {
		background: black;
	  }
	}

	&.active {
	  .lines {
		.line-1 {
		  background: white;
		}
		.line-2 {
		  background: white;
		}
		.line-3 {
		  background: white;
		}
	  }
	}
  }


}
.nav-btn {
  vertical-align: top;
  @include rem(width, 26px);
  @include rem(height, 26px);
  text-align: center;
  cursor: pointer;
  align-items: center;
  position: absolute;
  left: 15px;
  top: 0;

  .lines {
    @include rem(width, 26px);
    height: 2px;
    @include rem(flex, 0 0 26px);
    display: block;
    position: relative;
    position: absolute;
    top: 50%;
    @include rem(margin-top, -2px);
  }

  .line-1,
  .line-2,
  .line-3 {
    display: block;
    width: 100%;
    @include rem(height, 2px);
    background: #fff;
    transition: 0.1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.28571rem center;
  }

  .line-1 {
    top: 0;
    transition: opacity 0.1s 0.1s ease;
  }

  .line-2 {
    @include rem(top, 10px);
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  .line-3 {
    @include rem(top, -10px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  &.active {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
        background: #fff;
      }
    }
  }

  &.alt {
    .line-1,
    .line-2,
    .line-3 {
      background: #000;
    }

    &.active {
      .line-1,
      .line-2,
      .line-3 {
        background: #fff;
      }
    }
  }
}

.mobile-nav {
  width: 100%;
  display: none;
  text-align: center;
  @include rem(padding, 40px 0 60px 0);

  ul {
    margin: 0;

    li {
      margin: 0;
      padding: 0;
      text-transform: uppercase;

      &.active {
        ul {
          display: block;
        }

        a {
          .more {
            &:before {
              @include rotate(135deg);
              margin-top: -2px;
            }
          }
        }
      }

      a {
        text-decoration: none;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        @include rem(padding, 8px 0);
        position: relative;

        .more {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 2px solid #fff;
          position: relative;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -15px;

          &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            @include rotate(-45deg);
            @include transition(all, .3);
            transform-origin: 50% 50%;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -5px;
            margin-top: -5px;
          }
        }
      }
    }

    ul {
      display: none;
      @include rem(padding, 5px 0 15px 0);

      li {
        @include rem(font-size, 16px);

        a {
          @include rem(padding, 4px 0);
          color: rgba(255,255,255,.6);
        }
      }
    }
  }
}

.field-nav-mobile {
  display: none;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}