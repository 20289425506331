/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
  @include rem(font-size, 15px);
  line-height: 1.8em;
  background: #F2F2F2;
  border-bottom: 8px solid #93C900;
  @include rem(border-width, 8px);

  a {
    color: #000;
    text-decoration: none;
  }

  .copy {
    margin: 0;
  }

  .f_1 {
    @include rem(padding, 30px 0 0 0);
  }

  .f_2 {
    @include rem(padding, 0 0 15px 0);

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.foot-item {
  @include rem(margin, 30px 0);
}

.foot-nav {
  @include rem(margin-bottom, 10px);

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
      @include rem(font-size, 18px);
      line-height: 1.3em;
      border-left: 3px solid #000;
      padding: 0;
      @include rem(margin, 10px 0);

      a {
        color: #000;
        @include rem(padding, 0 20px);
        text-decoration: none;
        text-transform: uppercase;
        position: relative;
      }
    }
  }

  &.disabled {
    ul {
      flex-wrap: nowrap;

      li {
        flex-grow: 1;
        height: 23px;

        a {
          visibility: hidden;
        }

        &.theme-navy {
          a {
            display: none;
          }
        }

        &.theme-orange {
          flex: 0 0 17%;
          max-width: 17%;
        }

        &.theme-green {
          flex: 0 0 17%;
          max-width: 17%;
        }

        &.theme-red {
          flex: 0 0 13%;
          max-width: 13%;
        }

        &.theme-purple {
          flex: 0 0 19%;
          max-width: 19%;
        }

        &.theme-pink {
          flex: 0 0 12%;
          max-width: 12%;
        }

        &.theme-yellow {
          flex: 0 0 22%;
          max-width: 22%;
        }
      }
    }
  }
}

.foot-cols {
  .item {
    flex: 0 0 12.5%;
    max-width: 12.5%;

    .head {
      text-transform: uppercase;
      @include rem(margin-bottom, 10px);
      display: inline-block;
      position: relative;
      font-weight: 500;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 0;
        height: 1px;
        background: #000;
        @include transition(all, .3);
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }

  ul {
    li {
      padding: 0;
      margin: 0;

      a {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 2px;
          width: 0;
          height: 1px;
          background: #000;
          @include transition(all, .3);
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.logo-foot {
  display: flex;
  justify-content: space-between;
  @include rem(width, 234px);
  height: auto;

  .type {
    @include rem(max-width, 16px);
    height: auto;
    display: flex;
    align-items: center;

    &.alt {
      @include rem(max-width, 18px);
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }
}