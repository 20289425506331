/************************************************************************************
RESET
*************************************************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }

:focus { outline: 0; }

table { border-collapse: collapse; border-spacing: 0; }

article, aside, figure, footer, header, hgroup, nav, section, img { display: block; }

input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

label, input[type=button], input[type=submit], button { cursor: pointer; overflow: visible; }

em, i { font-weight: inherit; }

/************************************************************************************
GENERAL
*************************************************************************************/

body {
	font-family: Titillium Web,sans-serif;
	@include rem(font-size, 22px);
	line-height: 1.5em;
	font-weight: 300;
	color: #000;
	background: #fff;
	margin: 0;
	padding: 0;
	min-height: 100%;
}

/* Box sizing */

*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Anchors */

a {
	color: #0077bb;
	text-decoration: underline;
	@include transition(all, .3);

	&:hover {
		text-decoration: none;
	}
}

/* Heading */

h1, .h1 {
	font-family: Titillium Web,sans-serif;
	@include rem(font-size, 40px);
	line-height: 1.3em;
	font-weight: 200;
	color: #000;
	@include rem(margin, 0 0 30px 0);

	&.big {
		@include rem(font-size, 60px);
	}

	&.small {
		@include rem(font-size, 30px);
	}
}

h2, .h2 {
	font-family: Titillium Web,sans-serif;
	@include rem(font-size, 50px);
	line-height: 1.3em;
	font-weight: 400;
	letter-spacing: .05em;
	color: #000;
	@include rem(margin, 0 0 10px 0);

	&.medium {
		@include rem(font-size, 45px);
		font-weight: 400;
	}

	&.small {
		@include rem(font-size, 20px);
		font-weight: 300;
		letter-spacing: .1em;
		text-transform: uppercase;
	}
}

h3, .h3 {
	font-family: Titillium Web,sans-serif;
	@include rem(font-size, 32px);
	line-height: 1.3em;
	font-weight: 200;
	color: #000;
	@include rem(margin, 0 0 10px 0);

	&.small {
		@include rem(font-size, 18px);
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: .05em;
	}
}

h4, .h4 {
	font-family: Titillium Web,sans-serif;
	@include rem(font-size, 25px);
	line-height: 1.3em;
	font-weight: 200;
	color: #000;
	@include rem(margin, 0 0 10px 0);

	&.bold {
		font-weight: 600;
	}
}

h5, .h5 {
	font-family: Titillium Web,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 200;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h6, .h6 {
	font-family: Titillium Web,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 200;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

.suphead {
	@include rem(font-size, 17px);
	color: #8995a6;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

/* Paragraphs */

p {
	margin: 0 0 20px 0;
}

address, cite {
	font-style: normal;
}

/* Lists */

ul {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;

	li {
		@include rem(padding, 0 0 0 20px);
		@include rem(margin, 0 0 5px 0);
	}

	&.unstyled li {
		padding: 0;
		background: none;
	}
}

ol {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;
	counter-reset: counter;
	margin-left: 0;

	li {
		@include rem(margin, 0 0 10px 0);
		padding: 0 0 0 20px;
		counter-increment: counter;
		position: relative;

		&:before {
			color: #2285c8;
			content: counter(counter) ". ";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

dl {
	@include rem(margin, 0 0 20px 0);
}

dt {
	font-weight: bold;
}

/* Various */

sup, sub {
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}

sup {
	bottom: 1ex;
	font-size: 60%;
}

sub {
	top: .5ex;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.bold, b, strong {
	font-weight: 600;
}

.italic, i, em {
	font-style: italic;
}

.nowrap, .nobr {
	white-space: nowrap !important;
}

.ttu {
	text-transform: uppercase;
}

/************************************************************************************
CONTENT
*************************************************************************************/

.content {
	padding: 0;
	overflow: hidden;
	//max-width: 1920px;
	margin: 0 auto;
}

/* Block */

.block {
	@include rem(padding, 80px 0);
	position: relative;

	&.narrow {
		@include rem(padding, 40px 0);
	}

	&.alt {
		background: #000;
	}

	&.alpha {
		padding-top: 0;
	}

	&.omega {
		padding-bottom: 0;
	}
}

.anchor {
	position: absolute;
	left: 0;
	top: 0;
	//@include rem(top, -115px);
}

/* Various */

.section-head {
	@include rem(margin-bottom, 40px);
	text-align: center;

	h1, h2, h3 {
		margin-bottom: 0;
	}
}

.intro {
	max-width: 500px;
	@include rem(margin, 0 auto 40px auto);
	text-align: center;

	&.wide {
		@include rem(max-width, 750px);
	}
}

.sh-content {
	display: none;

	&.active {
		display: block;
	}
}

/* Pager */

.pager {
	@include rem(margin, 30px 0);
	text-align: center;
	position: relative;
	display: flex;
	justify-content: space-between;

	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	li {
		padding: 0;
		background: none;

		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include rem(width, 36px);
			@include rem(height, 36px);
			background: #666;
			text-align: center;

			&.active {
				background: #000;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.prev {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.next {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.counter {
		display: none;
	}
}

/*================================= Various Content =================================*/

.last {
	margin-bottom: 0 !important;
}

/* Buttons */

.btn,
.sendbutton {
	@include rem(font-size, 20px);
	font-family: Titillium Web,sans-serif;
	line-height: 1.3em;
	font-weight: 700;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: #0077bb;
	color: #fff;
	@include rem(padding, 12px 40px);
	text-decoration: none;
	position: relative;
	border: 2px solid #0077bb;
	@include border-radius(27px);
	@include transition(all, .5);

	&:hover {
		background-color: #000;
		border: 2px solid #000;
	}

	&.line {
		background: none;
		color: #0077bb;

		&:hover {
			background: #0077bb;
			border-color: #0077bb;
			color: #fff;
		}
	}

	&.alt {
		background: #000;
		border: 2px solid #000;

  	&:hover {
			background: #0077bb;
			border: 2px solid #0077bb;
  	}

  	&.line {
			background: none;
			color: #000;

			&:hover {
				background: #000;
				border-color: #000;
				color: #fff;
			}
		}
	}

	&.arr-r {
		&:after {
			content: '';
			border: solid #fff;
			border-width: 0 2.5px 2.5px 0;
		  display: inline-block;
		  padding: 3px;
		  margin-left: 5px;
		  @include rotate(-45deg);
		}
	}
}

/* SVG icons */

.icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}

/* Cookie Notice */

.cookie-notice {
	font-size: 14px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background: rgba(0,0,0,0.90);
	color: #CCC;
	text-align: center;
}

.cookie-button {
	display: inline-block;
	padding: 2px 10px;
	margin-left: 25px;
	background: #02ad65;
	color: #fff;
	text-align: center;

	&:hover {
		background: #90ca16;
		color: #fff;
	}
}

.cookie-test {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 150px;
	padding: 25px 15px;
	background: rgba(50,50,50,0.95);
	color: #CCC;
	text-align: center
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
	@include rem(margin, 0 0 40px 0);
	letter-spacing: 0.02em;

	h2, h3 {
		margin-top: 30px;
	}

	h2:first-child, h3:first-child {
		margin-top: 0;
	}

	ul li {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			@include rem(top, 12px);
			width: 4px;
			height: 4px;
			display: block;
			background: #000;
		}
	}
}

/************************************************************************************
TABLES
*************************************************************************************/

.entry table {
	width: 100%;
	@include rem(margin, 0 0 30px 0);

	th {
		font-weight: normal;
		border-bottom: 1px solid #c9cacb;
		@include rem(padding, 10px 20px);
		background: #e3e3e3;
		color: #000;
	}

	td {
		border-bottom: 1px solid #c9cacb;
		@include rem(padding, 10px 20px);
	}
}

/************************************************************************************
FORMS
*************************************************************************************/

form.std {
	label {
		@include rem(font-size,17px);
		line-height: 1.3em;
		color: #9f9f9f;
		@include rem(margin, 0 0 10px 0);
		display: block;
	}

	input.text {
		@include rem(font-size,17px);
		font-family: Titillium Web,sans-serif;
		line-height: 1.3em;
		color: #000;
		border: 2px solid #e7e7e7;
		@include rem(padding, 5px 15px);
		@include rem(height, 50px);
		width: 100%;

		&.error {
			background: #fcf4f4;
			border-color: #be1e19;
		}
	}

	textarea {
		@include rem(font-size,17px);
		font-family: Titillium Web,sans-serif;
		line-height: 1.3em;
		color: #000;
		resize: vertical;
		border: 2px solid #e7e7e7;
		@include rem(padding, 5px 15px);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;
	}

	select {
		@include rem(font-size,17px);
		line-height: 1.3em;
		color: #000;
		width: 100%;
		@include rem(padding, 2px 10px);
	}

	option {
		@include rem(font-size,17px);
		line-height: 1.3em;
		@include rem(padding, 2px 10px);
	}

	input.text[required="required"] {
		//background-image: url('../img/req.png');
		//background-position: right center;
		//background-repeat: no-repeat;
	}

	.cell {
		@include rem(margin-bottom, 20px);
		position: relative;
		text-align: left;
	}

	.toggle {
		position: relative;
		@include rem(padding, 0 0 0 25px);

		label {
			display: inline-block;
			vertical-align: top;
			margin: 0;
		}

		input {
			margin: 0;
			position: absolute;
			left: 0;
			@include rem(top, 2px);
		}
	}

	.radio, .checker {
		position: absolute;
		left: 0;
		top: 0;
	}
}

form.form-filter {
	position: relative;
	z-index: 250;

	input[type="checkbox"] {
		display: none;
	}

	padding-left: 70px;
	margin-top: -70px;
	margin-bottom: -30px;

	label {
		font-size: 13px;
		line-height: 1.5em;
		//@include rem(font-size, 13px);
		color: #fff;
		font-weight: 500;

		&:hover {
			color: #93C900;
		}
	}

	.cats {
		text-transform: uppercase;
	}

	.cell {
		margin-bottom: 0;
	}

	.toggle {
		padding: 0;

		&.about {
			@include rem(margin-bottom, 20px);
		}
	}

	.head {
		@include rem(font-size, 20px);
		display: flex;
		align-items: center;
		cursor: pointer;

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			@include rem(border-width, 11px 6.5px 0 6.5px);
			border-color: #fff transparent transparent transparent;
			@include rem(margin-left, 5px);
			@include transition(all, .3);
			@include rotate(0deg);
		}

		&.active {
			&:after {
				@include rotate(180deg);
			}
		}
	}

    .show-all {
		@include rem(font-size, 13px);
		margin-top: auto;
		margin-left: auto;
	  	padding: 0 16px;
  }
}

#form-ajax-content {
	position: relative;

	&.loading:after {
		content: '';
		position: absolute;
		z-index: 90;
		left: 50%;
		top: 50%;
		width: 70px;
		height: 70px;
		background: url('../img/spinner.svg') center center no-repeat;
		background-size: cover !important;
		@include translate(-50%,-50%);
	}

	& > .inner {
		opacity: 1;
		@include transition(all,.3);
	}

	&.loading > .inner {
		opacity: 0.4;
	}
}

/*================================= Cform =================================*/

.cform {
	legend {
		display: none;
	}

	label {
		display: none;
	}

	textarea {
		@include rem(font-size,17px);
		font-family: Titillium Web,sans-serif;
		color: #000;
		background: #fff;
		border: none;
		padding: 10px 15px;
		display: block;
		resize: vertical;
		margin: 0;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 190px;
		min-height: 190px;
		max-height: 300px;
	}

	input.single {
		@include rem(font-size,17px);
		font-family: Titillium Web,sans-serif;
		color: #000;
		background: #fff;
		padding: 10px 15px;
		border: none;
		display: inline-flex;
		align-items: center;
		width: 100%;
	}

	input[type="hidden"] {
		display: none;
	}

	.cf-ol {
		margin: 0 -10px;
		display: flex;
		flex-wrap: wrap;

		& > li {
			@include rem(margin, 0 0 20px 0);
			flex: 0 0 33.333333%;
			padding: 0 10px;

			&:before {
				content: '';
			}
		}
	}

	.cf-sb {
		float: none;
		margin: 0;
		display: flex;
		justify-content: center;
		width: 100%;
	}
}

.cf_hidden {
	display: none !important;
}

.emailreqtxt, .reqtxt {
	font-weight: 600;
	float: right;
	color: #f00808;
	position: absolute;
	right: 10px;
	top: 15px;
}

/* Form contact */

.form-contact {
	border: 3px solid #eff1f3;
	padding: 35px;
	max-width: 824px;
	margin: 0 auto;

	.cform {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: 0 auto;
	}

	.cf-fs1 {
		max-width: 33.33333%;
		flex: 0 0 33.33333%;
	}

	.cf-fs2 {
		max-width: 66.66666%;
		flex: 0 0 66.66666%;
		padding-left: 20px;
	}

	.cf-sb {

	}
}

/* Mesages & errors */

.cf_info {
	&.waiting {
		background: #f6e4cd;
		border: 1px solid #efc389;
		color: #ee9a2e;
		@include rem(margin, 0 0 30px 0);
	}

	&.failure {
		background: #fae7ea;
		border: 1px solid #f1c7d2;
		color: #e5547a;
		@include rem(padding, 15px 20px);
		@include rem(margin, 0 0 30px 0);
	}

	&.success {
		background: #ebf5bc;
		border: 1px solid #c7d198;
		color: #98b028;
		@include rem(margin, 0 0 30px 0);
	}

	span {
		font-weight: 600;
		@include rem(padding, 15px 20px);
		display: block;
	}
}

.cf_error,
.single.cf_error {
	border-color: #ff637d;
}

.cf_li_err {
	.single {
		border-color: #ff637d !important;
	}

	textarea {
		border-color: #ff637d !important;
	}

	label {
		color: #ee4d6c !important;

		a {
			color: #ee4d6c !important;
		}
	}
}

.cf_error,
.single.cf_error {
	border-color: #ff637d;
}

.cf_li_err {
	.single {
		border-color: #ff637d !important;
	}

	textarea {
		border-color: #ff637d !important;
	}

	label {
		color: #ee4d6c !important;

		a {
			color: #ee4d6c !important;
		}
	}
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
	position: relative;

	.tab-nav {
		ul {
			margin: 0 0 0 -1px;
			padding: 0;
		}

		li {
			float: left;
			list-style-type: none;
			padding: 0;
			background: none;
			margin: 0 0 0 1px;

			a {
				display: block;
				text-decoration: none;
				outline: 0;
				padding: 15px 30px;
				background: #000;
				color: #fff;

				&:hover {
					background: #a0a0a0;
					color: #000;
				}

				&.selected {
					background: #e3e3e3;
					color: #000;
				}
			}
		}
	}
	.tab-content .tab {
		float: left;
		width: 100%;
		padding: 30px;
		background: #e3e3e3;
		display: none;

		&.selected {
			position: static !important;
			display: block;
		}
	}
}

/************************************************************************************
QTIP
*************************************************************************************/

.qtip-default {
	padding: 8px 15px;
	background-color: #000;
	color: #000;
	@include border-radius(5px);
}

.qtip-content {
	@include rem(font-size, 16px);
	color: #fff;
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/

body {
	.slbArrow {
		font-size: 15px;

		&.prev {
			&:before {
				background: url('../img/slb-l.svg') center center no-repeat !important;
				width: 17px;
				height: 41px;
				background-size: cover !important;
				border: none;
			}

			&:hover {
				opacity: 1;
			}
		}

		&.next {
			&:before {
				background: url('../img/slb-r.svg') center center no-repeat !important;
				width: 17px;
				height: 41px;
				background-size: cover !important;
				border: none;
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	.slbCloseBtn {
		font-size: 0;
		letter-spacing: -800000px;
		line-height: 30px;
		font-weight: 400;
		font-family: Titillium Web,sans-serif;
		color: #fff;
		width: 30px;
		height: 30px;
		display: block;
		padding: 0;
		right: 40px;
		top: 20px;
		position: fixed;
		opacity: 0.8;
		background: url('../img/slb-close.svg') center center no-repeat !important;

		&:hover {
			opacity: 1;
			background: none;
		}
	}

	.slbWrapOuter {
		overflow-y: hidden;
	}

	.slbContentOuter {
		padding: 0 40px 0 40px;
	}

	.slbContent {
		@include rem(font-size, 18px);
	}

	.slbCaption {
		@include rem(font-size, 18px);
		line-height: 1.5em;
		position: relative;
		width: 100%;
		//position: relative;
		//width: 100%;
		/*position: relative;
		text-align: center;
		width: 100%;
		margin: 0;
		padding: 0;
		bottom: 70px;
		color: #fff;
		height: 60px;
		white-space: inherit;*/
	}

	.slbImageWrap {
		padding: 15px 0;
		max-height: 100vh !important;

		&:after {
			content: none;
		}
	}

	.slbImage {
		//padding: 15px 0 85px 0;
		//padding: 60px 0 60px 0;
		padding: 0;
		max-height: calc(100vh - 70px) !important;
	}
}

html.slbActive {
	body {
		//overflow: hidden;
	}
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0,0,0,.5);
  overflow: auto;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  .modal-content {
  	@include rem(padding, 30px);

  	&.alt {
  		background: #fff;
  	}
  }

  .modal-box {
	  @include rem(font-size, 18px);
	  @include rem(max-width, 400px);
	  background-color: #fff;
	  line-height: 1.5em;
	  margin: auto;
	  position: relative;

	  .modal-close {
	    position: absolute;
	    z-index: 10;
	    right: 15px;
	    top: 15px;
	    text-decoration: none;
	    @include rem(width, 40px);
	    @include rem(height, 40px);
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    color: #000;

	    .icon {
	    	@include rem(width, 30px);
	    	@include rem(height, 30px);
	    	transform: rotate(0deg);
	    	@include transition(all, .3);
	    }

	    &:hover {
	    	//background: #000;

	    	&:after {
	    		//color: #000;
	    	}

	    	.icon {
	    		transform: rotate(90deg);
	    	}
	    }
	  }

	  &.modal-author {
			width: 100%;
			@include rem(max-width, 1040px);
			background: #fff;
		}
	}

	.modal-box-wrap {
	  width: 100%;
	  //max-width: 500px;
	  @include rem(padding, 50px 15px 15px);
	  margin: auto;
	  overflow: hidden;
	  position: relative;
	  animation-name: animateTop;
	  animation-duration: .4s;
	}
}

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/************************************************************************************
SUDO SLIDERS
*************************************************************************************/

.slider {
	overflow: hidden;
	background: none;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: block;
	}

	li.panel {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: block;
		overflow: hidden;
		background: none;
	}
}

.slider-nav {
	display: block;
	position: static;
	margin-bottom: 30px;

	ol {
		margin: 0 -5px;
		display: flex;
		justify-content: center;
		top: 30px;
		height: 0;
	}

	li {
		margin: 0;
		padding: 0 5px;
		background: none;
		display: block;
		font-size: 0;

		a {
			width: 12px;
			height: 12px;
			display: block;
			background: #999;
			text-indent: -80000px;
			@include border-radius(50%);

			&:hover {
				background: #999;
			}
		}

		&.current a {
			background: #000;
		}
	}

	li:before {
		content: '';
	}

	.prev {
		position: absolute;
		@include rem(left,30px);
		top: 50%;
		@include translate(0,-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		@include rem(width,56px);
		@include rem(height,56px);
		@include border-radius(50%);
		background: #666;

		&:hover {
			background: #000;
		}

		&:after {
			content: '';
			border: solid #fff;
		  @include rem(border-width, 0 2.5px 2.5px 0);
		  display: inline-block;
		  @include rem(padding, 3px);
		  @include rem(margin-left, 3px);
		  @include rotate(135deg);
		}
	}

	.next {
		position: absolute;
		@include rem(right,30px);
		top: 50%;
		@include translate(0,-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		@include rem(width,56px);
		@include rem(height,56px);
		@include border-radius(50%);
		background: #666;

		&:hover {
			background: #000;
		}

		&:after {
			content: '';
			border: solid #fff;
		  @include rem(border-width, 0 2.5px 2.5px 0);
		  display: inline-block;
		  @include rem(padding, 3px);
		 	@include rem(margin-left, -3px);
		  @include rotate(-45deg);
		}
	}
}

/* Item slider */

.item-slider {
	position: relative;

	&.hide-nav {
		.slider-nav {
			display: none;
		}
	}
}

/* Service slider */

.service-slider {
	position: relative;

	li.panel {
		margin: 0;
	}
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}