/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
  h2, .h2 {
    @include rem(font-size, 50px);
  }

  .foot-cols {
    .item {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .column-left-negative {
    .column-inner {
      width: 57vw;
      flex: 0 0 57vw;
      width: calc(50.75vw + 60px);
      flex-basis: calc(50.75vw + 60px);
    }
  }

  .column-right-negative {
    .column-inner {
      width: 57vw;
      flex: 0 0 57vw;
      width: calc(50.75vw + 60px);
      flex-basis: calc(50.75vw + 60px);
    }
  }

  form.form-filter {
	.show-all {
	  padding: 0 15px;
	}
  }

}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }

  h2, .h2 {
    @include rem(font-size, 40px);
  }

  .main-nav {
    display: none;
  }

  .nav-btn {
    display: block;
  }

  .mobile-nav-wrap {
    display: block;
  }

  .header {
    .type {
      @include rem(max-width, 18px);

      &.alt {
        @include rem(max-width, 20px);
      }
    }
  }

  .block {
    @include rem(padding, 60px 0);
  }

  .teaser-nav {
    .link {
      &.big {
        @include rem(font-size, 20px);
        color: #fff !important;
      }
    }
  }

  .teaser-hp {
    .head {
      .h2 {
        @include rem(font-size, 35px);
      }
    }

    /*.teaser-content {
      .container {
        max-width: 100%;
      }
    }*/
  }

  .teaser-news-detail {
    .teaser-content {
      //height: auto;
      //min-height: 0;
    }

    .teaser-img, picture img {
      //position: relative;
    }
  }

  .primary-nav {
    //width: 150%;
    //margin-left: -25%;

    h1 {
      position: relative;
      text-align: center;
      max-width: 100%;
    }

    &.titled {
      @include rem(padding-left, 0);
      @include rem(margin-right, 0);
    }
  }

  .secondary-nav {
    ul {
      li {
        @include rem(font-size, 18px);
      }
    }
  }

  .column-left-negative {
    margin-left: 0;
    //padding-right: 0;
    margin-top: 0;
    order: 1;

    .column-inner {
      padding: 0;
      width: 100%;
      flex: 0 0 100%;
    }
  }

  .column-right {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    order: 0;

    .column-inner {
      max-width: 100%;
      padding-left: 15px;
    }
  }

  .column-left {
    margin-bottom: 0;
    margin-top: 0;

    .column-inner {
      max-width: 100%;
      padding: 0;
    }
  }

  .column-right-negative {
    margin-top: 0;

    .column-inner {
      padding: 0;
      width: 100%;
      flex: 0 0 100%;
    }
  }

  .project-address {
    @include rem(margin-bottom, 40px !important);
  }

  .news {
    .item {
      .img {
        @include rem(margin, 0 0 30px 0);
      }

      .text {
        .inner {
          padding: 0;
        }
      }
    }
  }

  .media {
    .item-list {
      margin: 0 -15px;
    }

    .item {
      padding: 0 15px;
    }
  }

  .project-info {
    .item-list {
      flex-wrap: wrap;
    }

    .item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin-bottom: 10px;
    }
  }

  .news-foot {
    .actions {
      .item-list {
        @include rem(margin, 0 -20px);
      }

      .item {
        @include rem(padding, 0 20px);
      }
    }
  }
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }

  .teaser-content {
    flex-wrap: wrap;
    flex-direction: column;

    h1 {
      margin-right: 0;
    }
  }

  .teaser.teaser-project {
    .teaser-content {
      flex-direction: row;
    }

    .teaser-img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .foot-cols {
    .item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .modal {
    .modal-content {
      padding: 50px 15px 15px 15px;
    }
  }

  .author-detail {
    .img {
      @include rem(margin-bottom, 30px);
    }
  }

  .nav {
	.nav-language-menu {
	  ul.lng {
		padding: 0 5px 0 0;
		li {
		  padding: 0 5px;

		  a {
			padding: 0;
		  }
		}
	  }
	}
  }

  .news-foot {
    flex-wrap: wrap;
    justify-content: center;

    .actions {
      .item {
        margin-bottom: 30px;
      }
    }

    .back {
      position: relative;
      top: 0;
      @include translate(0,0);
    }
  }
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }

  h2, .h2 {
    @include rem(font-size, 35px);

    &.medium {
      @include rem(font-size, 35px);
    }
  }

  h3, .h3 {
    @include rem(font-size, 30px);
  }

  .block {
    @include rem(padding, 40px 0);
  }

  .nav-btn {
    //left: 15px;
  }

  .teaser {
    min-height: 0;

    &.alt,
    &.empty,
    &.teaser-project {
      .teaser-content {
        @include rem(padding, 104px 0 40px 0);
        margin-top: 0;
      }
    }

    &.alt {
      .teaser-content {
        @include rem(padding-top, 164px);
      }
    }

    &.subpage-navigation {
      &.active {
        .teaser-content {
          //@include rem(padding-top, 50px);
        }
      }
    }

    .head {
      max-height: 500px;

      &.active {
        .h2 {
          max-height: 0;
        }

        .secondary-nav {
          position: relative;
          left: 0;
          top: 0;
          @include translate(0,0);
        }
      }

      &.disabled {
        display: none;
      }
    }
  }

  .teaser-home {
    .head {
      @include rem(padding-top, 50px);

      &.active {
        padding-top: 0;
      }
    }

    .mobile-nav {
      &.active {
        display: block;
      }
    }

    .teaser-content {
      min-height: 100vh;
    }
  }

  .teaser-field {
    .primary-nav {
      display: block;
      padding: 0;

      svg {
        max-width: 200%;
        margin-left: -50%;
        margin-bottom: -80px;
        margin-top: -50px;
      }

      h1 {
        display: none;
      }

      line, circle, .link, .forest {
        display: none !important;
      }

      &.reseted {
        display: none;
      }
    }
  }

  .teaser-project {
    min-height: 450px;

    .teaser-content {
      min-height: 450px;
    }
  }

  .teaser-content {
    height: auto;
    min-height: 0;

    h1 {
      @include rem(font-size, 30px);
    }
  }

  .teaser-news-detail {
    .teaser-content {
      //height: auto;
      min-height: 300px;
      @include rem(padding, 64px 0 0 0);
    }
  }

  .teaser-nav {
    @include rem(font-size, 12px);
    @include transition(all, 0);

    .nav-link.disabled {
      display: none !important;
    }
  }

  .primary-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
    @include rem(padding-top, 50px);

    &.active {
      display: block;
    }
  }

  .field-nav-mobile {
    width: 100%;
    display: block;
    text-align: center;
    @include rem(padding, 40px 0 40px 0);

    ul {
      margin: 0;

      li {
        margin: 0;
        padding: 0;
        text-transform: uppercase;

        .li-head {
          display: flex;
          align-items: center;
          justify-content: center;

          .more {
            width: 25px;
            height: 25px;
            flex: 0 0 25px;
            margin-left: 10px;
            border-radius: 50%;
            border: 2px solid #fff;
            position: relative;

            &:before {
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              @include rotate(-45deg);
              @include transition(all, .3);
              transform-origin: 50% 50%;
              border-left: 2px solid #fff;
              border-bottom: 2px solid #fff;
              position: absolute;
              left: 50%;
              top: 50%;
              margin-left: -5px;
              margin-top: -5px;
            }
          }
        }

        a {
          text-decoration: none;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          @include rem(padding, 8px 0);
          position: relative;
        }
      }

      ul {
        @include rem(padding, 5px 0 15px 0);

        li {
          @include rem(font-size, 16px);

          a {
            @include rem(padding, 4px 0);
          }
        }
      }
    }

    &.disabled {
      display: none;
    }
  }

  .nav {
	.nav-language-menu {
	  ul.lng {
		li {
		  padding: 0 9px;

		  a {
			padding: 0;
		  }
		}
	  }
	}
  }

  .scroll-down {
    display: none;
  }

  .projects {
    .item-list {
      margin-left: -6px;
      margin-right: -6px;
    }

    .item {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .foot-cols {
    .item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .logo-foot {
    max-width: 140px;
  }

  .contacts {
    .item {
      @include rem(margin-bottom, 30px);
    }
  }

  .persons {
    .item-list {
      margin: 0 -10px;
    }

    .item {
      padding: 0 10px;
    }
  }

  .project-info {
    padding: 15px 15px 0 15px;

    .item {
      flex: 0 0 100%;
      max-width: 100%;
      border: none;
      border-bottom: 1px solid #000;
      @include rem(padding, 10px 0);
      margin: 0;
    }
  }

  form.form-filter {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  body.hp {
    .mobile-nav-wrap {
      display: block !important;
    }
  }
}