/* field, project */
.block-non-project + .block-project { padding-top: 2.5rem; }
.block-project + .block-non-project { padding-top: 2.5rem; }
.block-quote + .block-video { margin-top: 5rem; }

/* news */
.block-content-quote:last-of-type { padding-bottom: 2.5rem; }
.block-content-video:last-of-type { margin-bottom: 2.5rem; }


/* editmode */
.teaser-nav .pimcore_tag_link { display: flex !important; max-height: 30px; max-width: 130px; }