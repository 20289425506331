/************************************************************************************
LAYOUT
*************************************************************************************/

.video {
  img {
    width: 100%;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  }

  .play {
    position: absolute;
    left: 50%;
    top: 50%;
    @include rem(width, 64px);
    @include rem(height, 64px);
    @include rem(margin-top, -32px);
    @include rem(margin-left, -32px);
    border-radius: 50%;
    background: rgba(255,255,255,1);
    z-index: 20;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      @include rem(margin-left, -10px);
      @include rem(margin-top, -15px);
      width: 0;
      height: 0;
      border-style: solid;
      @include rem(border-width, 15px 0 15px 20px);
      border-color: transparent transparent transparent #000;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
    @include transition(all, .5);
  }

  &:hover {
    &:after {
      background: rgba(0,0,0,.1);
    }
  }
}

.photo {
  img {
    width: 100%;
  }
}

.quote {
  letter-spacing: .1em;

  h3, .h3 {
    font-weight: 400;
  }

  .cite {
    @include rem(font-size, 22px);
    line-height: 1.5em;
    margin: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .sign {
    @include rem(font-size, 20px);
    @include rem(margin-top, 30px);
    margin-bottom: 0;
    text-align: right;
  }

  &.small {
    .cite {
      @include rem(font-size, 22px);
    }

    .sign {
      @include rem(font-size, 20px);
    }
  }
}

.project-nav {
  ul {
    border-top: 2px solid #000;
    margin: 0;

    li {
      @include rem(font-size, 18px);
      line-height: 1.3em;
      font-weight: 500;
      letter-spacing: .05em;
      text-transform: uppercase;
      padding: 0;
      margin: 0;

      a {
        color: #000;
        text-decoration: none;
        display: block;
        @include rem(padding, 10px 0);
        border-bottom: 1px solid #000;
        @include transition(all,.3);

        &:hover {
          //padding-left: 10px;

          .note {
            //@include rem(margin-top, -10px);
            //max-height: 30px;
            height: 30px;
            display: block;
            white-space: nowrap;
          }
        }

        .note {
          @include rem(font-size, 14px);
          text-transform: none;
          display: block;
          color: #000;
          margin-top: 0;
          overflow: hidden;
          //max-height: 0;
          display: none;
          @include transition(all, .5);
        }
      }
    }
  }

  &:hover {
    margin-bottom: -30px;
  }
}

.projects {
  border-top: 2px solid #000;
  @include rem(padding-top, 80px);
  overflow: hidden;

  h2 {
    color: #000 !important;
  }

  .item-list {
    @include rem(margin, 0 -25px);
  }

  .item {
    @include rem(padding, 0 25px);
    @include rem(margin-bottom, 30px);

    .img {
      @include rem(margin-bottom, 10px);
      display: block;
      position: relative;
      overflow: hidden;
      background-size: cover;

      img {
        @include transition(all, .3);
        //@include scale(1);
        width: 100%;
      }

      &:hover {
        img {
          //@include scale(1.05);
        }
      }
    }

    .hover {
      background-size: cover;
      @include transition(all, .3);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-blend-mode: luminosity;
      //-webkit-filter: grayscale(1);
      //filter: grayscale(1);
      opacity: 0;
    }

    h3 {
      color: #000;
      @include transition(all, .3);

      a {
        color: #000;
        text-decoration: none;
      }
    }
  }

  .item-inner {
    text-decoration: none;

    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }
}

.heading {
  @include rem(margin-bottom, 30px);

  .address {
    @include rem(padding-top, 30px);
    @include rem(max-width, 220px);

    ul {
      li {
        padding: 0;
        line-height: 1.2em;

        a {
          text-decoration: none;
          color: #707070;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 0;
            height: 1px;
            background: #707070;
            @include transition(all, .3);
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }

        &.link {
          @include rem(margin-top, 20px);
        }
      }
    }

    b, strong {
      font-weight: 600;
    }
  }
}

.column-left-negative {
  @include rem(margin-top, 40px);
  @include rem(margin-bottom, 40px);
  order: 0;
  display: flex;
  justify-content: flex-end;

  .column-inner {
    padding-right: 15px;
    width: 57vw;
    flex: 0 0 57vw;
    width: calc(50.75vw + 75px);
    flex-basis: calc(50.75vw + 75px);
  }
}

.column-right {
  @include rem(margin-top, 40px);
  @include rem(margin-bottom, 40px);
  order: 1;

  .column-inner {
    position: relative;
    //max-width: 460px;
    max-width: 551px;
    padding-right: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.column-right-negative {
  @include rem(margin-top, 40px);
  @include rem(margin-bottom, 40px);

  .column-inner {
    padding-left: 15px;
    width: calc(50.75vw + 75px);
    flex-basis: calc(50.75vw + 75px);
  }
}

.column-left {
  display: flex;
  justify-content: flex-end;
  @include rem(margin-top, 40px);
  @include rem(margin-bottom, 40px);

  .column-inner {
    //max-width: 555px;
    //max-width: 460px;
    max-width: 551px;
    padding-right: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .column-inner-inner {
      position: relative;
    }
  }
}

.project-params {
  @include rem(font-size, 17px);
  line-height: 1.5em;
  @include rem(padding, 30px 0);
  @include rem(margin-bottom, 40px);
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background: #000;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #000;
  }

  .head {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0;
  }

  ul {
    &:last-child {
      margin-bottom: 0;
    }

    li {
      padding: 0;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.project-desc {
  @include rem(margin-bottom, 40px);

  h2 {
    text-transform: uppercase;
    color: #000 !important;
    @include rem(margin-bottom, 20px);
  }

  &:last-child {
    //margin-bottom: 0;
  }
}

.project-history {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  @include rem(margin-bottom, 40px);

  .info-item {
    @include rem(font-size, 15px);
    @include rem(padding, 15px 0);
    border-bottom: 1px solid #747474;

    &:last-child {
      border-bottom: none;
    }
  }

  .head {
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  dl {
    display: flex;
    justify-content: space-between;
    margin: 0;
    max-width: 240px;
  }

  ul {
    &:last-child {
      margin: 0;
    }

    li {
      margin: 0;
      padding: 0;

      .name {
        font-weight: 400;
      }

      a {
        color: #000 !important;
        text-decoration: none;
      }
    }
  }
}

.project-link {
  max-width: 230px;
  margin: 0 auto;
  @include rem(margin-bottom, 40px);
  display: block;
  color: #000 !important;
  text-decoration: none;
  text-transform: uppercase;

  .img {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .text {
    @include rem(font-size, 14px);
    line-height: 1.5em;
    letter-spacing: .1em;
    text-align: center;
    border-bottom: 1px solid #000;
    @include rem(padding, 15px);
    @include rem(margin-bottom, 15px);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background: #000;
      @include transition(all, .3);
    }

    b {
      display: block;
    }
  }

  &:hover {
    color: #fff !important;

    .text {
      &:before {
        height: 100%;
      }
    }
  }

  &:last-child {
    //margin-bottom: 0;
  }
}

.project-address {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include rem(margin-bottom, 40px);
  width: 100%;
  align-self: flex-end;

  &:last-child {
    margin-bottom: 0;
  }

  .text {
    @include rem(font-size, 18px);
    display: flex;
    align-items: flex-end;
	line-height: 1.3;

    a {
      color: #000;
      text-decoration: none;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        @include rem(bottom, 2px);
        width: 0;
        height: 1px;
        background: #000;
        @include transition(all, .3);
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }

  .img {
    @include rem(padding-left, 30px);
  }

  ul {
    margin-bottom: 0;

    li {
      line-height: 1.3em;
      padding: 0;
      margin: 0;
    }
  }
}

.photo-tiles {
  position: relative;

  .item-list {
    @include rem(margin-left, -6px);
    @include rem(margin-right, -6px);
    margin-bottom: -10px;
  }

  .item {
    padding: 0;
    margin: 0;

    .img {
      overflow: hidden;
      position: relative;
      display: block;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 6px solid #fff;
      }

      &:hover {
        img {
          @include scale(1.05);
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include scale(1);
      @include transition(all, .3);
    }
  }
}

.scroll-up {
  @include rem(font-size, 14px);
  line-height: 1.5em;
  color: #fff;
  text-decoration: none;
  letter-spacing: .1em;
  text-align: center;
  @include transition(all, .3);

  .text {
    color: #000 !important;
  }

  .icon {
    display: block;
    @include rem(margin, 0 auto 20px auto);
    @include rem(width, 35px);
    @include rem(height, 35px);
  }
}

.story {
  letter-spacing: .1em;
}

.contacts {
  .item-list {
    @include rem(margin-bottom, -50px);
  }

  .item {
    @include rem(font-size, 16px);
    @include rem(margin-bottom, 50px);

    ul {
      li {
        line-height: 1.5em;
        padding: 0;
        margin: 0;
      }
    }

    .note {
      @include rem(font-size, 13px);
      line-height: 1.5em;
      margin: 0;
    }
  }
}

.persons {
  .item-list {
    @include rem(margin, 0 -25px);
  }

  .item {
    @include rem(font-size, 14px);
    line-height: 1.5em;
    @include rem(margin-bottom, 50px);
    @include rem(padding, 0 25px);

    .img {
      @include rem(margin-bottom, 30px);
    }

    .head {
      @include rem(margin-bottom, 30px);
    }

    h3,
    .job {
      @include rem(font-size, 16px);
      font-weight: 300;
      line-height: 1.5em;
      text-transform: uppercase;
      margin: 0;
    }
  }
}

.news {
  .item {
    .img {
      display: block;

      .inner {
        height: 100%;
      }

      a {
        display: block;
        height: 100%;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .text {
      @include rem(font-size, 15px);
      line-height: 1.5em;

      h2 {
        @include rem(margin-bottom, 50px);

        line-height: 1em;

        a {
          color: #000;
          text-decoration: none;
        }
      }

      .inner {
        @include rem(padding, 20px 0 20px 25px);
      }

      .info {
        @include rem(font-size, 16px);
        @include rem(margin-bottom, 20px);

        ul {
          margin: 0;

          li {
            padding: 0;
          }
        }
      }

      .more {
        border-top: 1px solid #707070;
        @include rem(padding-top, 20px);

        a {
          text-decoration: none;
          text-transform: uppercase;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            @include rem(bottom, 2px);
            width: 0;
            height: 1px;
            background: #000;
            @include transition(all, .3);
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.news-load-more {
	opacity: 0;
	pointer-events: none;
}

.media {
  @include rem(font-size, 15px);
  line-height: 1.5em;

  .item-list {
    @include rem(margin, 0 -75px);
  }

  .item {
    @include rem(padding, 0 75px);

    h2 {
      a {
        color: #000 !important;
        text-decoration: none;
      }
    }

    .info {
      @include rem(font-size, 16px);
      @include rem(margin, 10px 0);

      p {
        margin: 0;
      }
    }

    .more {
      @include rem(font-size, 16px);
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #000;
      @include rem(padding-top, 10px);

      a {
        margin-right: 10px;
        text-decoration: none;
      }
    }
  }

  .item-inner {
    @include rem(padding, 0 0 70px 0);
  }
}

.project-info {
  @include rem(font-size, 15px);
  @include rem(padding, 25px 0);

  .item-list {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }

  .item {
    padding: 0 15px;
    border-right: 1px solid #000;

    dl {
      margin-bottom: 0;
    }

    dt {
      line-height: 1.3em;
      text-transform: uppercase;
      font-weight: 300;
      @include rem(margin-bottom, 10px);
    }

    dd {
      line-height: 1.3em;
    }

    &:last-child {
      border: none;
    }
  }
}

.author-detail {
  @include rem(padding-bottom, 30px);

  h2 {
    color: #000;
    text-transform: uppercase;
    @include rem(margin-bottom, 10px);
  }

  .subhead {
    @include rem(font-size, 17px);
    @include rem(margin, 10px 0);
  }

  img {
	width: 100%;
  }

  .foot {
    @include rem(padding-top, 20px);

    a {
      border-bottom: 1px solid #000;
	  text-decoration: none;
    }
  }

  .row {
    margin: 0 -20px;

    & > * {
      padding: 0 20px;
    }
  }

  .text {
    //display: flex;
    //flex-direction: column;

    .inner {
      height: 100%;
      display: flex;
     flex-direction: column;
     justify-content: space-between;
    }
  }
}

.author-gallery {
  .item {
    @include rem(margin-bottom, 30px);
  }
}

.news-head {
  @include rem(font-size, 16px);
  @include rem(line-height, 25px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  @include rem(padding-bottom, 20px);
  @include rem(margin-bottom, 20px);

  .meta {
    @include rem(padding, 5px 0);

    a {
      color: #000;
    }

    .date {
      margin: 0;
    }
  }

  .actions {
    display: flex;
    @include rem(padding, 5px 0);

    .head {
      @include rem(margin, 0 10px 0 0);
    }

    ul {
      @include rem(margin, 0 -5px);
      display: flex;

      li {
        margin: 0;
        @include rem(padding, 0 5px);

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          //background: #000;

          .icon {
            @include rem(width, 25px);
            @include rem(height, 25px);
          }
        }
      }
    }
  }
}

.news-foot {
  @include rem(font-size, 16px);
  @include rem(line-height, 25px);
  @include rem(padding-top, 40px);
  @include rem(margin-top, 20px);
  display: flex;
  align-items: center;
  border-top: 2px solid #000;
  position: relative;

  .head {
    @include rem(font-size, 14px);
    text-transform: uppercase;
    text-align: center;
  }

  .actions {
    max-width: 574px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    .item-list {
      @include rem(margin, 0 -53px);
    }

    .item {
      @include rem(padding, 0 53px);

      .head {
        border-bottom: 2px solid #000;
        width: 100%;
        @include rem(padding-bottom, 10px);
        @include rem(margin-bottom, 10px);
      }

      ul {
        display: flex;
        justify-content: center;
        @include rem(margin, 0 -13px);

        li {
          @include rem(padding, 0 13px);
          margin: 0;
        }
      }

     .icon {
        @include rem(width, 30px);
        @include rem(height, 30px);
      }
    }
  }

  .back {
    position: absolute;
    left: 0;
    top: 50%;
    @include translate(0,-50%);

    .icon {
      @include rem(width, 35px);
      @include rem(height, 35px);
      display: block;
    }

    .txt {
      color: #000;
      text-align: center;
      display: block;
    }
  }
}

.tags {
  a {
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
}

.news-foot {
  a {
    @include rem(font-size, 14px);
    color: #000;
    text-decoration: none;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@-webkit-keyframes move{
  from {
    @include scale(1);
  }
  to {
    @include scale(1.15);
  }
}

@keyframes move{
  from {
    @include scale(1);
  }
  to {
    @include scale(1.15);
  }
}

// Color themes

$themeColors: (
  "general": #93C900,
  "orange": #FF6C00,
  "green": #7F9F88,
  "red": #ED2B12,
  "purple": #8B3F83,
  "pink": #FFACEF,
  "yellow": #E3CF62,
  "navy": #92A0D0,
  "blue": #374FB7
);

@each $themeClass, $themeColor in $themeColors {
  .theme-#{$themeClass} {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    a,
    .project-nav ul li.active a,
    .project-nav ul li a:hover {
      color: $themeColor;
    }

    .projects {
      border-top: 2px solid $themeColor;

      .item {
        h3 {
          a {
            &:hover {
              color: $themeColor;
            }
          }
        }
      }
    }

    .project-params {
      &:before {
        background: $themeColor;
      }

      &:after {
        background: $themeColor;
      }
    }

    .project-address {
      color: $themeColor;
    }

    .teaser {
      .scroll-down {
        .icon {
          color: $themeColor;
          @include rem(width, 35px);
          @include rem(height, 35px);
        }

        &:hover {
          color: $themeColor;
        }
      }
    }

    .teaser-nav {
      &.sub-nav {
        a:hover,
        a.active {
          color: $themeColor !important;
        }
      }

      &.hp-nav {
        a.activated {
          color: $themeColor;
        }
      }

      circle {
        &.active {
          stroke: $themeColor;
          fill: $themeColor;
        }
      }

      line {
        &.active {
          stroke: $themeColor;
        }
      }

      .link-svg {
        color: $themeColor !important;
      }

      .link {
        &.small {
          &:hover {
            color: $themeColor !important;
          }
        }

        &.active {
          //color: $themeColor !important;
        }
      }
    }

    .footer {
      border-bottom: 8px solid $themeColor;
      @include rem(border-width, 8px);
    }

    .foot-nav {
      ul {
        li {
          border-color: $themeColor;
        }
      }
    }

    .video {
      .play {
        &:after {
          border-color: transparent transparent transparent $themeColor;
        }

        &:hover {
          background: $themeColor;

         &:after {
          border-color: transparent transparent transparent #fff;
         }
        }
      }
    }

    .heading {
      .address {
        color: $themeColor;
      }
    }

    .more {
      a:after {
        background: $themeColor !important;
      }
    }

    .project-history {
      border-color: $themeColor !important;
    }

    .author-detail {
      .foot {
        a {
          border-color: $themeColor;
        }
      }
    }

    .projects {
      .hover {
        background-color: $themeColor;
      }

      .item-inner {
        &:hover {
          h3 {
            color: $themeColor;
          }
        }
      }
    }

    .news-head {
      border-bottom: 2px solid $themeColor;

      .actions {
        ul {
          li {
            a {
              color: $themeColor;
            }
          }
        }
      }
    }

    .news-foot {
      border-color: $themeColor;

      a {
        color: $themeColor;
      }

      .actions {
        .head {
          border-color: $themeColor;
        }
      }
    }

    .teaser-project {
      .nav-inactive {
        .head {
          .subhead {
            a:hover {
              color: $themeColor;
            }
          }
        }
      }
    }

    .field-nav-mobile {
      .li-head {
        color: $themeColor;

        .more {
          border-color: $themeColor;

          &:before {
            border-color: $themeColor;
          }
        }
      }
    }
  }
}

@each $themeClass, $themeColor in $themeColors {
  .foot-nav {
    ul {
      li.theme-#{$themeClass} {
        border-color: $themeColor;

        a:hover {
          color: $themeColor;
        }
      }
    }
  }

  .mobile-nav {
    .primary {
      li.theme-#{$themeClass} {
        a:hover {
          color: $themeColor;
        }

        &.active {
          a {
            color: $themeColor;
          }
        }
      }
    }
  }

  .footer {
    a {
      color: $themeColor;
    }
  }

  .teaser-nav {
    a.active.theme-#{$themeClass} {
      color: $themeColor !important;
    }
  }

  .media {
    .item.theme-#{$themeClass} {
      .more {
        a {
          color: $themeColor !important;
        }
      }
    }
  }

  .toggle.theme-#{$themeClass} {
    &.active {
      label {
        color: $themeColor !important;
      }
    }

	input:checked + label {
		color: $themeColor !important;
	}

    label:hover {
      color: $themeColor !important;
    }
  }
  .toggle.about {
    &.active {
      label {
        color: #93C900 !important;
      }
    }

	input:checked + label {
		color: #93C900 !important;
	}

    label:hover {
      color: #93C900 !important;
    }
  }
  .toggle.year {
	&.active {
	  label {
		color: #93C900 !important;
	  }
	}
  }
}

.theme-yellow .projects .hover {
	background: #dbc845;
}

.theme-red .projects .hover {
	background: #be160d;
}